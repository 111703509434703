import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { AppStateProvider } from './context/FileContext.tsx'

import * as Sentry from '@sentry/react'

Sentry.init({
    dsn: 'https://993e8a9e745adb4af3107706502bfb1a@o4507078182109184.ingest.us.sentry.io/4507081381052416',
    integrations: [],
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <AppStateProvider>
            <App />
        </AppStateProvider>
    </React.StrictMode>
)
