import { OutputPDFProvider } from './context/OutputPDFContext.tsx'
import { InstrumentsProvider } from './context/InstrumentsContext.tsx'
import { PlaybackControlProvider } from './context/PlaybackContext.tsx'
import { AppLogic } from './context/AppLogic.tsx'
import MultiInstrumentPlayer from './MultiInstrumentPlayer.tsx'
import WakeLockManager from './components/WakeLock.tsx'
import { useAppStateContext } from './context/FileContext.tsx'
import { useRef } from 'react'
import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { HeaderMenu } from './components/HeaderMenu.tsx'
import { PlaybackControlAffix } from './components/PlaybackControlAffix.tsx'
import {
    Affix,
    AppShell,
    Burger,
    Card,
    Container,
    Group,
    NavLink,
    ScrollArea,
    Space,
    Stack,
    Tabs,
    Text,
} from '@mantine/core'
import Split from 'react-split-grid'
import RightSidebarContents from './components/RightSidebarContents.tsx'
import ExportPDFButton from './components/ExportPDFButton.tsx'
import {
    IconAdjustments,
    IconEdit,
    IconFile,
    IconLayoutGrid,
} from '@tabler/icons-react'
import OpenFileButton from './components/OpenFileButton.tsx'
import SaveFileButton from './components/SaveFileButton.tsx'
import { CodeEditor } from './components/CodeEditor.tsx'
import PieceGridView from './components/PieceGridView.tsx'
import IframePDFViewer from './components/IframePDFViewer.tsx'

const codeEditorView = <CodeEditor />

const pieceGridView = <PieceGridView />

const pdfViewer = (
    <div
        className="split-pane"
        id="pdf-viewer"
        // style={{
        //     height: 'calc(100vh - var(--app-shell-header-height, 100px) - var(--app-shell-footer-height, 100px))',
        // }}
    >
        <IframePDFViewer></IframePDFViewer>
    </div>
)

const visualPieceView = (
    <>
        <Space />
        {/*<Card>*/}
        {pieceGridView}
        {/*</Card>*/}

        <Space m={'xl'} />
        <Container>
            <Card radius="md">
                <RightSidebarContents showPlaybackItemControl={false} />
            </Card>
        </Container>
        <Space mt="xl" />

        {/*<ExternalLinksView />*/}
        {/*<Space mt="xl" />*/}
    </>
)

const fullCodeView = (
    <>
        <PlaybackControlAffix />
        <ScrollArea
            type={'auto'}
            h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px) - 50px) "
        >
            {codeEditorView}
        </ScrollArea>
    </>
)

const previewView = (
    <>
        <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
            {visualPieceView}
        </ScrollArea>
    </>
)

const headerMenu = <HeaderMenu />

const sidebarView = (
    <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
        <RightSidebarContents showPlaybackItemControl={true} />
    </ScrollArea>
)

const logicComponents = (
    <>
        <AppLogic />
        <MultiInstrumentPlayer />
        <WakeLockManager />
    </>
)

export function PieceContainer() {
    const {
        activePieceView,
        setActivePieceView,
        activeLeftPieceView,
        mobileOpened,
        toggleMobile,
        closeMobile,
    } = useAppStateContext()

    const viewportRef = useRef<HTMLDivElement>(null)
    const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure()
    const isMobile = useMediaQuery('(max-width: 768px)')
    const griddivhorizontal = useRef(null)

    const grid = (
        <>
            <Split
                //@ts-expect-error split plane missing typescript definition
                render={({
                    //@ts-expect-error split plane missing typescript definition
                    getGridProps,
                    //@ts-expect-error split plane missing typescript definition
                    getGutterProps,
                }) => (
                    <div
                        ref={griddivhorizontal}
                        className="grid grid-horizontal"
                        {...getGridProps()}
                    >
                        <div className="split-pane" id="left-pane">
                            {headerMenu}
                            {activeLeftPieceView == 'code' && fullCodeView}
                            {activeLeftPieceView == 'preview' && previewView}
                        </div>

                        <div
                            className="resize-gutter gutter-col gutter-col-1"
                            {...getGutterProps('column', 1)}
                        />

                        {pdfViewer}

                        <div
                            className="resize-gutter gutter-col gutter-col-2"
                            {...getGutterProps('column', 3)}
                        />

                        <div className="split-pane" id="audio-sidebar">
                            {sidebarView}
                        </div>
                    </div>
                )}
            />
        </>
    )

    const editorView = (
        <>
            {/*<PieceViewSwitcher />*/}
            <Stack gap={'0'} className={'mainStack'}>
                {grid}
            </Stack>
        </>
    )

    const mobileView = (
        <Tabs value={activePieceView} onChange={() => toggleMobile()}>
            {/*<Tabs.List>*/}
            {/*    <Tabs.Tab value="first">First tab</Tabs.Tab>*/}
            {/*    <Tabs.Tab value="second">Second tab</Tabs.Tab>*/}
            {/*</Tabs.List>*/}

            <Tabs.Panel value="editor">
                {/*<PlaybackControlAffix />*/}
                <ScrollArea h="calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))">
                    {codeEditorView}
                </ScrollArea>
            </Tabs.Panel>
            <Tabs.Panel value="notes">
                <Affix
                    position={{ top: 12, right: 10 }}
                    hidden={activePieceView != 'notes'}
                >
                    <ExportPDFButton />
                </Affix>
                <Stack
                    h={
                        'calc(100vh - var(--app-shell-header-height, 0px) - var(--app-shell-footer-height, 0px))'
                    }
                >
                    {pdfViewer}
                </Stack>
            </Tabs.Panel>
            {/*<Tabs.Panel value="library">*/}
            {/*    <Split*/}
            {/*        //@ts-expect-error split plane missing typescript definition*/}
            {/*        render={({*/}
            {/*            //@ts-expect-error split plane missing typescript definition*/}
            {/*            getGridProps,*/}
            {/*            //@ts-expect-error split plane missing typescript definition*/}
            {/*            getGutterProps,*/}
            {/*        }) => (*/}
            {/*            <div*/}
            {/*                ref={griddivhorizontal}*/}
            {/*                className="grid grid-two"*/}
            {/*                {...getGridProps()}*/}
            {/*            >*/}
            {/*                <LibraryView />*/}
            {/*                */}
            {/*                <div*/}
            {/*                    className="resize-gutter gutter-col gutter-col-1"*/}
            {/*                    {...getGutterProps('column', 1)}*/}
            {/*                />*/}

            {/*                <div className="split-pane" id="audio-sidebar">*/}
            {/*                    <ScrollArea h={'calc(100vh - 60px)'}>*/}
            {/*                        <RightSidebarContents />*/}
            {/*                    </ScrollArea>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    />*/}
            {/*</Tabs.Panel>*/}
            <Tabs.Panel value="audiocontrols">
                <ScrollArea.Autosize mah="calc(100vh - var(--mantine-header-height, 0px) - var(--mantine-footer-height, 0px))">
                    <Container mt={'xl'} mb="7rem">
                        <RightSidebarContents showPlaybackItemControl={true} />
                    </Container>
                </ScrollArea.Autosize>
            </Tabs.Panel>
            <Tabs.Panel value="piecegrid">
                <ScrollArea h={'calc(100vh - 60px)'}>
                    {pieceGridView}
                    <Space mt="5rem" />
                </ScrollArea>
            </Tabs.Panel>
        </Tabs>
    )

    const appcontent = !isMobile ? editorView : mobileView

    const appshell = (
        <AppShell
            header={{ height: 60 }}
            navbar={{
                width: 300,
                breakpoint: 'sm',
                collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
            }}
            footer={{ height: 0 }}
            padding={0}
            // hidden
            disabled={!isMobile}
        >
            <AppShell.Header>
                <Group h="100%" px="md">
                    <Burger
                        opened={mobileOpened}
                        onClick={toggleMobile}
                        hiddenFrom="sm"
                        size="sm"
                    />
                    <Burger
                        opened={desktopOpened}
                        onClick={toggleDesktop}
                        visibleFrom="sm"
                        size="sm"
                    />
                    <Text fs="30" fw={600}>
                        GrooveDoc
                        {/*{parsedPiece?.properties.name*/}
                        {/*    ? parsedPiece?.properties.name*/}
                        {/*    : 'GrooveDoc'}*/}
                    </Text>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md">
                {/*<NavLink*/}
                {/*    label="Library"*/}
                {/*    leftSection={<IconHome2 size="1rem" stroke={1.5} />}*/}
                {/*    onClick={() => setActivePieceView('library')}*/}
                {/*/>*/}
                <NavLink
                    active={activePieceView === 'piecegrid'}
                    label="Playback"
                    leftSection={<IconLayoutGrid size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('piecegrid')
                        closeMobile()
                    }}
                />
                <NavLink
                    active={activePieceView === 'notes'}
                    label="Notation"
                    leftSection={<IconFile size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('notes')
                        closeMobile()
                    }}
                />
                <NavLink
                    active={activePieceView === 'editor'}
                    label="Editor"
                    leftSection={<IconEdit size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('editor')
                        closeMobile()
                    }}
                />

                <NavLink
                    active={activePieceView === 'audiocontrols'}
                    label="Audio Settings"
                    leftSection={<IconAdjustments size="1rem" stroke={1.5} />}
                    onClick={() => {
                        setActivePieceView('audiocontrols')
                        closeMobile()
                    }}
                />

                <Space mt={'md'} />
                <OpenFileButton />
                <Space mt={'md'} />
                <SaveFileButton />
                {/*<Space mt={'md'} />*/}
                <NavLink
                    label={'Privacy Policy'}
                    href={
                        'https://www.privacypolicies.com/live/47d6f0f5-3d92-4ad2-b2e5-0e78430148ed'
                    }
                    mt={'2rem'}
                />
            </AppShell.Navbar>
            <AppShell.Main>
                <Container fluid ref={viewportRef} p={0} m={0}>
                    {appcontent}
                </Container>
            </AppShell.Main>
            <AppShell.Footer p={0} m={0}></AppShell.Footer>
        </AppShell>
    )

    return (
        <>
            <OutputPDFProvider>
                <InstrumentsProvider>
                    <PlaybackControlProvider>
                        {logicComponents}
                        {appshell}
                    </PlaybackControlProvider>
                </InstrumentsProvider>
            </OutputPDFProvider>
        </>
    )
}
