import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { Instrument } from '../Instrument.tsx'

interface InstrumentsContextType {
    instruments: { [key: string]: Instrument }
    setInstruments: (instruments: { [key: string]: Instrument }) => void
}

const InstrumentsContext = createContext<InstrumentsContextType | null>(null)

export const useInstruments = () => useContext(InstrumentsContext)!

export const InstrumentsProvider: FC<{ children?: ReactNode | undefined }> = (
    props
) => {
    const [instruments, setInstruments] = useState<{
        [key: string]: Instrument
    }>({})

    return (
        <InstrumentsContext.Provider value={{ instruments, setInstruments }}>
            {props.children}
        </InstrumentsContext.Provider>
    )
}
