import { createContext, useContext, useState } from 'react'

interface OutputPDFContextType {
    pdfUrl: string | null
    setPdfUrl: (url: string | null) => void
}
const OutputPDFContext = createContext<OutputPDFContextType | null>(null)

export const OutputPDFProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null)

    return (
        <OutputPDFContext.Provider value={{ pdfUrl, setPdfUrl }}>
            {children}
        </OutputPDFContext.Provider>
    )
}

export const useOutputPDF = () => {
    return useContext(OutputPDFContext)!
}
