import { forwardRef, useImperativeHandle, useRef } from 'react'
import './FlashingCircle.css'

// Define the interface for imperative methods.
export interface FlashingCircleRef {
    flashLeft: () => void
    flashRight: () => void
}

const FlashingCircle = forwardRef<
    FlashingCircleRef,
    { isMuted: boolean; onClick?: () => void }
>(({ isMuted, onClick }, ref) => {
    const leftRef = useRef<HTMLDivElement>(null)
    const rightRef = useRef<HTMLDivElement>(null)

    // This helper toggles the 'flash' class on the element.
    const triggerFlash = (element: HTMLDivElement | null) => {
        if (!element) return
        // Remove the class in case it’s already there.
        element.classList.remove('flash')
        // Force a reflow to allow re-adding the class to restart the animation.
        void element.offsetWidth
        element.classList.add('flash')
    }

    // Expose the imperative flash methods to the parent via the ref.
    useImperativeHandle(ref, () => ({
        flashLeft: () => triggerFlash(leftRef.current),
        flashRight: () => triggerFlash(rightRef.current),
    }))

    return (
        <div className={`circle ${isMuted ? 'muted' : ''}`}>
            <div
                ref={leftRef}
                className="half left"
                onClick={onClick}
                // onClick={() => triggerFlash(leftRef.current)}
            >
                <span className="half-label">L</span>
            </div>
            <div
                ref={rightRef}
                className="half right"
                onClick={onClick}
                // onClick={() => triggerFlash(rightRef.current)}
            >
                <span className="half-label">R</span>
            </div>
        </div>
    )
})

export default FlashingCircle
