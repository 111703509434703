import React, { createContext, useContext, useRef, useState } from 'react'
import { initial_editor_content } from '../IntialEditorContent.tsx'
import { useDebouncedValue, useDisclosure } from '@mantine/hooks'
import { useSessionStorage } from '@mantine/hooks'
import {
    InstrumentEvent,
    InstrumentEventCallback,
} from '../types/InstrumentEvent'

// Define the type for the context state
interface FileContextType {
    editorContent: string
    setEditorContent: (text: string) => void
    fileName: string
    setFileName: (fileName: string) => void
    debouncedEditorContent: string
    selectedLine: number
    setSelectedLine: (line: number) => void
    bpm: number
    setBpm: (bpm: number) => void
    microtimingStrength: number
    setMicrotimingStrength: (strength: number) => void
    microtimingPattern: number[] | null
    setMicrotimingPattern: (pattern: number[] | null) => void
    mutedInstruments: Set<string>
    setMutedInstruments: (mutedInstruments: Set<string>) => void
    playbackFollowsTextCursor: boolean
    setPlaybackFollowsTextCursor: (val: boolean) => void
    toggleInstrumentMute: (instrumentName: string) => void
    subscribeInstrumentEvent: (callback: InstrumentEventCallback) => void
    unsubscribeInstrumentEvent: (callback: InstrumentEventCallback) => void
    publishInstrumentEvent: (event: InstrumentEvent) => void
}

interface AppStateContextType {
    activePieceView: string | null
    setActivePieceView: (view: string | null) => void
    activeLeftPieceView: string
    setActiveLeftPieceView: (view: string) => void
    mobileOpened: boolean
    toggleMobile: () => void
    closeMobile: () => void
}

const FileContext = createContext<FileContextType | undefined>(undefined)

const AppStateContext = createContext<AppStateContextType | null>(null)

export function useFileContext() {
    return useContext(FileContext)!
}

export function useAppStateContext() {
    return useContext(AppStateContext)!
}

export const AppStateProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [activePieceView, setActivePieceView] = useState<string | null>(
        'piecegrid'
    )

    const [activeLeftPieceView, setActiveLeftPieceView] =
        useState<string>('preview')

    const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] =
        useDisclosure()

    return (
        <AppStateContext.Provider
            value={{
                activePieceView,
                setActivePieceView,
                activeLeftPieceView,
                setActiveLeftPieceView,
                mobileOpened,
                toggleMobile,
                closeMobile,
            }}
        >
            {children}
        </AppStateContext.Provider>
    )
}

// Provider component
export const FileProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [fileName, setFileName] = useState<string>('piece.txt')

    const [editorContent, setEditorContent] = useSessionStorage({
        key: 'editor-content',
        defaultValue:
            document.getElementById('shared_piece')?.textContent?.trim() ||
            initial_editor_content,
    })

    const [debouncedEditorContent] = useDebouncedValue(editorContent, 300)

    const [selectedLine, setSelectedLine] = useState<number>(0)

    const [bpm, setBpm] = useState<number>(115)

    const [microtimingPattern, setMicrotimingPattern] = useState<
        number[] | null
    >(null)
    const [microtimingStrength, setMicrotimingStrength] = useState<number>(0.0)

    const [mutedInstruments, setMutedInstruments] = useState(new Set<string>())

    const [playbackFollowsTextCursor, setPlaybackFollowsTextCursor] =
        useState<boolean>(true)

    function toggleInstrumentMute(instrumentName: string) {
        if (mutedInstruments.has(instrumentName)) {
            mutedInstruments.delete(instrumentName)
        } else {
            mutedInstruments.add(instrumentName)
        }
        setMutedInstruments(new Set(mutedInstruments))
    }

    const instrumentEventSubscribersRef = useRef(
        new Set<InstrumentEventCallback>()
    )

    const subscribeInstrumentEvent = (callback: InstrumentEventCallback) => {
        instrumentEventSubscribersRef.current.add(callback)
    }

    // Unsubscribe: remove a callback from the set.
    const unsubscribeInstrumentEvent = (callback: InstrumentEventCallback) => {
        instrumentEventSubscribersRef.current.delete(callback)
    }

    const publishInstrumentEvent = (event: InstrumentEvent) => {
        instrumentEventSubscribersRef.current.forEach((callback) =>
            callback(event)
        )
    }

    return (
        <FileContext.Provider
            value={{
                editorContent,
                setEditorContent,
                fileName,
                setFileName,
                debouncedEditorContent,
                selectedLine,
                setSelectedLine,
                bpm,
                setBpm,
                microtimingPattern,
                setMicrotimingPattern,
                microtimingStrength,
                setMicrotimingStrength,
                mutedInstruments,
                setMutedInstruments,
                playbackFollowsTextCursor,
                setPlaybackFollowsTextCursor,
                toggleInstrumentMute,
                subscribeInstrumentEvent,
                unsubscribeInstrumentEvent,
                publishInstrumentEvent,
            }}
        >
            {children}
        </FileContext.Provider>
    )
}
