import React, { useEffect, useRef } from 'react'
import FlashingCircle, { FlashingCircleRef } from './FlashingCircle.tsx'
import { useFileContext } from '../context/FileContext.tsx'
import { Flex, Skeleton } from '@mantine/core'
import { InstrumentEvent } from '../types/InstrumentEvent'
import './Drums.css'
import Balafon, { BalafonHandle } from './Balafon.tsx'
import { useParsedPiece } from '../context/ParsedPieceContext.tsx'

function isMuted(event_instrument: string, mutedInstruments: Set<string>) {
    return Array.from(mutedInstruments).some((i) =>
        event_instrument.includes(i)
    )
}

const InstrumentsView: React.FC = () => {
    const {
        subscribeInstrumentEvent,
        unsubscribeInstrumentEvent,
        toggleInstrumentMute,
        mutedInstruments,
    } = useFileContext()

    const { presentInstrumentNames } = useParsedPiece()

    if (!presentInstrumentNames) {
        return <Skeleton height={'2rem'} radius={'md'} mt={'sm'} />
    }

    // This helper toggles the 'flash' class on the element.
    const triggerFlash = (element: HTMLDivElement | null) => {
        if (!element) return
        // Remove the class in case it’s already there.
        element.classList.remove('flash')
        // Force a reflow to allow re-adding the class to restart the animation.
        void element.offsetWidth
        element.classList.add('flash')
    }

    const djembeRef = useRef<FlashingCircleRef>(null)
    const dundunbaDrumRef = useRef<HTMLDivElement>(null)
    const dundunbaBellRef = useRef<HTMLDivElement>(null)
    const sanbanDrumRef = useRef<HTMLDivElement>(null)
    const sanbanBellRef = useRef<HTMLDivElement>(null)
    const kenkeniDrumRef = useRef<HTMLDivElement>(null)
    const kenkeniBellRef = useRef<HTMLDivElement>(null)
    const balafonRef = useRef<BalafonHandle>(null)

    useEffect(() => {
        const callback = (event: InstrumentEvent) => {
            // return if any string in mutedInstruments is contained in event.instrument
            if (
                Array.from(mutedInstruments).some((instrument) =>
                    event.instrument.includes(instrument)
                )
            ) {
                return
            }

            switch (event.instrument) {
                case 'h1_djembe_1_default':
                    if (event.handing) {
                        djembeRef.current?.flashRight()
                    } else {
                        djembeRef.current?.flashLeft()
                    }
                    break
                case 'h1_dundunba_1_drum':
                    triggerFlash(dundunbaDrumRef.current)
                    break
                case 'h1_dundunba_1_bell':
                    triggerFlash(dundunbaBellRef.current)
                    break
                case 'h1_sanban_1_drum':
                    triggerFlash(sanbanDrumRef.current)
                    break
                case 'h1_sanban_1_bell':
                    triggerFlash(sanbanBellRef.current)
                    break
                case 'h1_kenkeni_1_drum':
                    triggerFlash(kenkeniDrumRef.current)
                    break
                case 'h1_kenkeni_1_bell':
                    triggerFlash(kenkeniBellRef.current)
                    break
                case 'h2_balafon_default':
                    balafonRef.current?.triggerHit(event.note, 'o')
                    break
                default:
                    console.log('Unknown instrument:', event.instrument)
            }
        }

        subscribeInstrumentEvent(callback)
        // console.log('Subscribed to instrument events')

        return () => {
            unsubscribeInstrumentEvent(callback)
            // console.log('Unsubscribed from instrument events')
        }
    }, [mutedInstruments])

    return (
        <Flex
            // mih={50}
            gap={{ base: 'xs', lg: 'xl' }}
            justify="center"
            align="flex-start"
            direction="row"
            wrap="wrap"
        >
            <div className="drums-container">
                {/* Dundunba (largest drum) */}
                {presentInstrumentNames.includes('dundunba') && (
                    <>
                        <div
                            className={`hitarea drum dundunba ${isMuted('dundunba', mutedInstruments) ? 'muted' : ''}`}
                            ref={dundunbaDrumRef}
                            onClick={() => toggleInstrumentMute('dundunba')}
                        >
                            <span className="drum-label">D</span>
                        </div>
                        <div
                            className={`hitarea bell dundunba-bell ${isMuted('dundunba', mutedInstruments) ? 'muted' : ''}`}
                            ref={dundunbaBellRef}
                        />
                    </>
                )}
                {presentInstrumentNames.includes('sanban') && (
                    <>
                        <div
                            className={`hitarea drum sangban ${isMuted('sanban', mutedInstruments) ? 'muted' : ''}`}
                            ref={sanbanDrumRef}
                            onClick={() => toggleInstrumentMute('sanban')}
                        >
                            <span className="drum-label">S</span>
                        </div>
                        <div
                            className={`hitarea bell sangban-bell ${isMuted('sanban', mutedInstruments) ? 'muted' : ''}`}
                            ref={sanbanBellRef}
                        />
                    </>
                )}
                {presentInstrumentNames.includes('kenkeni') && (
                    <>
                        <div
                            className={`hitarea drum kenkeni ${isMuted('kenkeni', mutedInstruments) ? 'muted' : ''}`}
                            ref={kenkeniDrumRef}
                            onClick={() => toggleInstrumentMute('kenkeni')}
                        >
                            <span className="drum-label">K</span>
                        </div>
                        <div
                            className={`hitarea bell kenkeni-bell ${isMuted('kenkeni', mutedInstruments) ? 'muted' : ''}`}
                            ref={kenkeniBellRef}
                        />
                    </>
                )}
            </div>
            {presentInstrumentNames.includes('djembe') && (
                <FlashingCircle
                    ref={djembeRef}
                    isMuted={isMuted('djembe', mutedInstruments)}
                    onClick={() => toggleInstrumentMute('djembe')}
                />
            )}
            {presentInstrumentNames.includes('balafon') && (
                <Balafon
                    ref={balafonRef}
                    minHeight={100}
                    maxHeight={150}
                    keyWidth={16}
                    ropeColor={'#c3c5aa'}
                    ropeOffset={20}
                    fontSize={9}
                    labelTop={30}
                    keyCornerRadius={4}
                    keySpacing={2}
                    showNoteNames={true}
                    // onHit={(note: string, hitType: string) => {
                    //     console.log(`Hit ${note} with ${hitType}`)
                    // }}
                    pitchLevels={[
                        'G2',
                        'A2',
                        'B2',
                        'C3',
                        'D3',
                        'E3',
                        'F3',
                        'G3',
                        'A3',
                        'B3',
                        'C4',
                        'D4',
                        'E4',
                        'F4',
                        'G4',
                        'A4',
                        'B4',
                        'C5',
                        'D5',
                        'E5',
                        'F5',
                        'G5',
                    ]}
                />
            )}
        </Flex>
    )
}

export default InstrumentsView
