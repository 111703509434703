import React, { useState, useEffect } from 'react'
import { useOutputPDF } from '../context/OutputPDFContext.tsx'

interface IframePDFViewerProps {}

const IframePDFViewer: React.FC<IframePDFViewerProps> = () => {
    const [iframeSrc, setIframeSrc] = useState<string | null>(null)

    const { pdfUrl } = useOutputPDF()

    useEffect(() => {
        setIframeSrc(pdfUrl)
    }, [pdfUrl])

    return <>{iframeSrc && <iframe src={iframeSrc}></iframe>}</>
}

export default IframePDFViewer
