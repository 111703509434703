import { SegmentedControl, VisuallyHidden, rem, darken } from '@mantine/core'
import { IconHeadphones, IconPencil } from '@tabler/icons-react'
import { useAppStateContext } from '../context/FileContext.tsx'

export function EditPreviewSwitch() {
    const iconProps = {
        style: { width: rem(20), height: rem(20), display: 'block' },
        stroke: 1.5,
    }

    const { activeLeftPieceView, setActiveLeftPieceView } = useAppStateContext()

    return (
        <SegmentedControl
            size="xs"
            radius={'xl'}
            autoContrast
            color={darken('#657500', 0.4)}
            onChange={setActiveLeftPieceView}
            value={activeLeftPieceView}
            data={[
                {
                    value: 'preview',
                    label: (
                        <>
                            <IconHeadphones {...iconProps} />
                            <VisuallyHidden>Preview</VisuallyHidden>
                        </>
                    ),
                },
                {
                    value: 'code',
                    label: (
                        <>
                            <IconPencil {...iconProps} />
                            <VisuallyHidden>Code</VisuallyHidden>
                        </>
                    ),
                },
                // {
                //     value: 'export',
                //     label: (
                //         <>
                //             <IconExternalLink {...iconProps} />
                //             <VisuallyHidden>Export</VisuallyHidden>
                //         </>
                //     ),
                // },
            ]}
        />
    )
}
