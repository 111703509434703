import { useEffect } from 'react'
import axios from 'axios'
import { useActivePlaybackItem } from './PlaybackContext'
import { useFileContext } from './FileContext.tsx'
import { usePrevious } from '@mantine/hooks'
import { useOutputPDF } from './OutputPDFContext.tsx'
import { useParsedPiece } from './ParsedPieceContext.tsx'

export function AppLogic() {
    const {
        debouncedEditorContent,
        selectedLine,
        setBpm,
        setMicrotimingStrength,
        setMicrotimingPattern,
        playbackFollowsTextCursor,
    } = useFileContext()

    const { parsedPiece, setParsedPiece } = useParsedPiece()

    const { setPdfUrl } = useOutputPDF()

    const { selectedPlaybackItem, setSelectedPlaybackItem } =
        useActivePlaybackItem()

    const reloadPattern = (val: string) => {
        let ignore = false
        axios
            .post('/api/piece/parse/', val, {
                headers: { 'Content-Type': 'text/plain' },
            })
            .then(
                (response) => {
                    if (ignore) return
                    const data = response.data
                    setParsedPiece(data)
                },
                (error) => {
                    if (ignore) return
                    console.log(error)
                }
            )
        return () => {
            ignore = true
        }
    }

    const previousePieceBpm = usePrevious(parsedPiece?.bpm)
    const previousePieceMicrotimingStrength = usePrevious(
        parsedPiece?.microtiming_strength
    )
    const previousePieceMicrotimingPattern = usePrevious(
        parsedPiece?.microtiming_pattern
    )
    useEffect(() => {
        if (parsedPiece) {
            // update bpm from piece if it changes there
            if (
                parsedPiece.properties.bpm !== previousePieceBpm ||
                !previousePieceBpm
            ) {
                setBpm(parsedPiece.properties.bpm!)
            }
            if (
                parsedPiece.microtiming_strength !==
                previousePieceMicrotimingStrength
            ) {
                setMicrotimingStrength(parsedPiece.microtiming_strength)
            }
            if (
                parsedPiece.microtiming_pattern !==
                previousePieceMicrotimingPattern
            ) {
                setMicrotimingPattern(parsedPiece.microtiming_pattern)
            }
        }
    }, [parsedPiece])

    useEffect(() => {
        // console.log('debouncedEditorContent changed', debouncedEditorContent)
        reloadPattern(debouncedEditorContent)
    }, [debouncedEditorContent])

    useEffect(() => {
        // console.log('line selected:', selectedLine, 'parsed piece', parsedPiece)
        if (!playbackFollowsTextCursor) return

        if (!parsedPiece) {
            setSelectedPlaybackItem(null)
            return
        }

        if (!(selectedLine.toString() in parsedPiece.line_map)) {
            const first = parseInt(Object.keys(parsedPiece.line_map)[0])
            const last = parseInt(Object.keys(parsedPiece.line_map)[-1])
            if (selectedLine > first && selectedLine <= last)
                setSelectedPlaybackItem(null)
        } else {
            const entry = parsedPiece.line_map[selectedLine]
            setSelectedPlaybackItem({
                name: entry.element_name || 'Standard',
                instrument_name: null,
                itemtype:
                    entry.element_type == 'voice' ? 'part' : entry.element_type,
            })
        }

        if (!selectedPlaybackItem) {
            // set first from line map
            const first = parseInt(Object.keys(parsedPiece.line_map)[0])
            const entry = parsedPiece.line_map[first]
            setSelectedPlaybackItem({
                name: parsedPiece.line_map[first].element_name || 'Standard',
                instrument_name: null,
                itemtype:
                    entry.element_type == 'voice' ? 'part' : entry.element_type,
            })
        }
    }, [selectedLine, parsedPiece, playbackFollowsTextCursor])

    useEffect(() => {
        let ignore = false

        const updatePDFURL = async () => {
            try {
                const response = await fetch('/api/create-pdf/', {
                    method: 'POST',
                    body: debouncedEditorContent,
                })
                if (ignore) return

                const blob = await response.blob()
                const url = URL.createObjectURL(blob)

                setPdfUrl(url)

                // Cleanup function for revoking the blob URL
                return () => {
                    URL.revokeObjectURL(url)
                }
            } catch (error: unknown) {
                // @ts-expect-error error is unknown
                if (error.name !== 'AbortError') {
                    console.error('Error:', error)
                }
            }
        }

        updatePDFURL()

        return () => {
            ignore = true
        }
    }, [debouncedEditorContent])

    return <></>
}
