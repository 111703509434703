import { createContext, FC, ReactNode, useContext, useState } from 'react'
import { ParsedPiece } from '../types/ParsedPiece'

interface ParsedPieceContextType {
    parsedPiece: ParsedPiece | null
    setParsedPiece: (piece: ParsedPiece | null) => void
    presentInstrumentNames: string[]
}
const ParsedPieceContext = createContext<ParsedPieceContextType | null>(null)

export const useParsedPiece = () => useContext(ParsedPieceContext)!

export const ParsedPieceProvider: FC<{ children?: ReactNode | undefined }> = (
    props
) => {
    const [parsedPiece, setParsedPiece] = useState<ParsedPiece | null>(null)

    const presentInstrumentNames = parsedPiece
        ? parsedPiece.instrument_names.filter((instrumentName) =>
              parsedPiece.playback_items.some(
                  (item) => item.instrument_name === instrumentName
              )
          )
        : []

    return (
        <ParsedPieceContext.Provider
            value={{ parsedPiece, setParsedPiece, presentInstrumentNames }}
        >
            {props.children}
        </ParsedPieceContext.Provider>
    )
}
