import React, {
    useRef,
    useCallback,
    useMemo,
    forwardRef,
    useImperativeHandle,
} from 'react'
import './Balafon.css'

type HitType = 'o' | 'x'

export interface BalafonProps {
    pitchLevels: string[]
    minHeight?: number
    maxHeight?: number
    keyWidth?: number
    keyColor?: string
    keyCornerRadius?: number
    keySpacing?: number // New prop for spacing between keys
    ropeColor?: string
    ropeHeight?: number
    showNoteNames?: boolean
    ropeOffset?: number
    fontSize?: number // New prop for font size
    labelTop?: number
    onHit?: (note: string, hitType: HitType) => void
    onNoteClick?: (event: React.MouseEvent, note: string) => void
}

export interface BalafonHandle {
    triggerHit: (noteName: string, hitType: HitType) => void
}

// Separate component for the rope - pure rendering
const Rope: React.FC<{
    position: 'top' | 'bottom'
    offset: number
    color: string
    height: number
}> = React.memo(({ position, offset, color, height }) => (
    <div
        className={`rope ${position}-rope`}
        style={{ [position]: `${offset}px` }}
    >
        <div
            className="rope-inner"
            style={{
                backgroundColor: color,
                height: `${height}px`,
            }}
        />
    </div>
))

Rope.displayName = 'Rope'

// Component for note label - pure rendering
const NoteLabel: React.FC<{
    note: string
    color: string
    fontSize: number
    top: number
}> = React.memo(({ note, color, fontSize, top }) => (
    <div
        className="note-label"
        style={{
            color,
            fontSize: `${fontSize}px`, // Apply custom font size
            top: `${top}px`,
        }}
    >
        {note}
    </div>
))

NoteLabel.displayName = 'NoteLabel'

// Animation utility function - simplified without colored circles
const animateHit = (keyEl: HTMLElement | null, _hitType: HitType) => {
    if (!keyEl) return

    // Flash animation using DOM classes
    keyEl.classList.remove('flash')
    // Force a reflow to allow re-adding the class to restart the animation.
    void keyEl.offsetWidth
    keyEl.classList.add('flash')
}

// Individual key component
const BalafonKey = React.memo(
    forwardRef<
        HTMLDivElement,
        {
            note: string
            index: number
            totalKeys: number
            minHeight: number
            maxHeight: number
            keyWidth: number
            keyColor: string
            keyCornerRadius: number
            keySpacing: number // Added prop
            ropeColor: string
            ropeHeight: number
            showNoteNames: boolean
            ropeOffset: number
            fontSize: number // Added prop
            labelTop: number
            onHit: (note: string, hitType: HitType) => void
            onNoteClick?: (event: React.MouseEvent, note: string) => void
        }
    >((props, ref) => {
        const {
            note,
            index,
            totalKeys,
            minHeight,
            maxHeight,
            keyWidth,
            keyColor,
            keyCornerRadius,
            keySpacing, // Using new prop
            ropeColor,
            ropeHeight,
            showNoteNames,
            ropeOffset,
            fontSize, // Using new prop
            labelTop,
            onHit,
            onNoteClick,
        } = props

        // Calculate height based on index position
        const reversedIndex = totalKeys - index - 1
        const heightRatio = totalKeys > 1 ? reversedIndex / (totalKeys - 1) : 0
        const keyHeight = minHeight + (maxHeight - minHeight) * heightRatio

        // Use internal ref for the DOM element
        const keyElementRef = useRef<HTMLDivElement>(null)

        // Forward the ref
        React.useImperativeHandle(
            ref,
            () => keyElementRef.current as HTMLDivElement
        )

        // Click handler - optimized with useCallback
        const handleKeyClick = useCallback(
            (event: React.MouseEvent) => {
                // Use custom click handler if provided
                if (onNoteClick) {
                    onNoteClick(event, note)
                    return
                }

                // Default behavior - determine hit type based on click position
                const rect = (
                    event.currentTarget as HTMLElement
                ).getBoundingClientRect()
                const clickY = event.clientY - rect.top
                const hitType: HitType = clickY < keyHeight / 2 ? 'o' : 'x'

                // Animate the hit
                animateHit(keyElementRef.current, hitType)

                // Call the onHit callback
                onHit(note, hitType)
            },
            [keyHeight, note, onHit, onNoteClick]
        )

        // Memoize styles to prevent recreation
        const keyContainerStyle = useMemo(
            () => ({
                width: `${keyWidth}px`,
                margin: `0 ${keySpacing}px`, // Apply custom spacing
            }),
            [keyWidth, keySpacing]
        )

        const keyStyle = useMemo(
            () => ({
                height: `${keyHeight}px`,
                width: `${keyWidth}px`,
                backgroundColor: keyColor,
                borderRadius: `${keyCornerRadius}px`,
            }),
            [keyHeight, keyWidth, keyColor, keyCornerRadius]
        )
        return (
            <div
                className="balafon-key-container"
                onClick={handleKeyClick}
                style={keyContainerStyle}
                data-note={note}
            >
                <div
                    ref={keyElementRef}
                    className="balafon-key"
                    style={keyStyle}
                >
                    <Rope
                        position="top"
                        offset={ropeOffset}
                        color={ropeColor}
                        height={ropeHeight}
                    />
                    <Rope
                        position="bottom"
                        offset={ropeOffset}
                        color={ropeColor}
                        height={ropeHeight}
                    />

                    {showNoteNames && (
                        <NoteLabel
                            note={note}
                            color={ropeColor} // Using rope color for text as requested
                            fontSize={fontSize}
                            top={labelTop}
                        />
                    )}
                </div>
            </div>
        )
    })
)

BalafonKey.displayName = 'BalafonKey'

// Main Balafon component
const Balafon = forwardRef<BalafonHandle, BalafonProps>((props, ref) => {
    const {
        pitchLevels,
        minHeight = 166,
        maxHeight = 281,
        keyWidth = 34,
        keyColor = '#5C2B16',
        keyCornerRadius = 8,
        keySpacing = 2, // Default key spacing
        ropeColor = '#E1E3C5',
        ropeHeight = 8,
        showNoteNames = true,
        ropeOffset = 45,
        fontSize = 14, // Default font size
        labelTop = 56,
        onHit = () => {},
        onNoteClick,
    } = props

    // Validate height props
    const validatedMinHeight = Math.max(50, minHeight) // Ensure minimum is at least 50px
    const validatedMaxHeight = Math.max(validatedMinHeight + 20, maxHeight) // Ensure max is greater than min

    // Store refs to key elements
    const keyRefsMap = useRef<Map<string, HTMLDivElement>>(new Map())

    // Ref callback to store/remove element references
    const setKeyRef = useCallback(
        (note: string, element: HTMLDivElement | null) => {
            if (element) {
                keyRefsMap.current.set(note.toUpperCase(), element)
            } else {
                keyRefsMap.current.delete(note)
            }
        },
        []
    )

    // Expose the triggerHit method via ref
    useImperativeHandle(
        ref,
        () => ({
            triggerHit: (noteName: string, hitType: HitType) => {
                const keyElement = keyRefsMap.current.get(
                    noteName.toUpperCase()
                )
                if (keyElement) {
                    // Animate the hit
                    animateHit(keyElement, hitType)

                    // Call the onHit callback
                    onHit(noteName, hitType)
                }
            },
        }),
        [onHit]
    )

    // Memoize keys array to prevent unnecessary recreations
    const balafonKeys = useMemo(() => {
        return pitchLevels.map((note, index) => (
            <BalafonKey
                key={`${note}-${index}`}
                ref={(el) => setKeyRef(note, el)}
                note={note}
                index={index}
                totalKeys={pitchLevels.length}
                minHeight={validatedMinHeight}
                maxHeight={validatedMaxHeight}
                keyWidth={keyWidth}
                keyColor={keyColor}
                keyCornerRadius={keyCornerRadius}
                keySpacing={keySpacing} // Pass the new prop
                ropeColor={ropeColor}
                ropeHeight={ropeHeight}
                showNoteNames={showNoteNames}
                ropeOffset={ropeOffset}
                fontSize={fontSize} // Pass the new prop
                labelTop={labelTop}
                onHit={onHit}
                onNoteClick={onNoteClick}
            />
        ))
    }, [
        pitchLevels,
        validatedMinHeight,
        validatedMaxHeight,
        keyWidth,
        keyColor,
        keyCornerRadius,
        keySpacing, // Add to dependency array
        ropeColor,
        ropeHeight,
        showNoteNames,
        ropeOffset,
        fontSize, // Add to dependency array
        onHit,
        onNoteClick,
        setKeyRef,
    ])
    console.log('Balafon rendered')
    return (
        <div className="balafon-container">
            <div className="balafon-keys">{balafonKeys}</div>
        </div>
    )
})

Balafon.displayName = 'Balafon'

export default Balafon
