import { createTheme, MantineColorsTuple, MantineProvider } from '@mantine/core'
import '@mantine/core/styles.css'
import '@mantine/notifications/styles.css'
import './App.css'
import { FileProvider } from './context/FileContext.tsx'

import { Notifications } from '@mantine/notifications'
import { PieceContainer } from './PieceContainer.tsx'
import { ParsedPieceProvider } from './context/ParsedPieceContext.tsx'

function App() {
    const myColor: MantineColorsTuple = [
        '#fff8df',
        '#fff0ca',
        '#ffdf99',
        '#ffcd63',
        '#ffbe36',
        '#ffb518',
        '#ffb003',
        '#e49a00',
        '#cb8800',
        '#b07500',
    ]

    const theme = createTheme({
        colors: {
            prim: myColor,
        },
        primaryColor: 'prim',
        // primaryColor: 'FFBC2E',
    })

    return (
        <MantineProvider defaultColorScheme="dark" theme={theme}>
            <Notifications />
            <ParsedPieceProvider>
                <FileProvider>
                    <PieceContainer />
                </FileProvider>
            </ParsedPieceProvider>
        </MantineProvider>
    )
}

export default App
