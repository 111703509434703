import {
    AspectRatio,
    Button,
    Container,
    darken,
    Group,
    Paper,
} from '@mantine/core'
import { IconPlayerPlay, IconPlayerStop } from '@tabler/icons-react'
import { useFileContext } from '../context/FileContext.tsx'
import { useElementSize } from '@mantine/hooks'
import {
    useActivePlaybackItem,
    usePlaybackControl,
} from '../context/PlaybackContext.tsx'
import { useParsedPiece } from '../context/ParsedPieceContext.tsx'

export function PlaybackControlAffix() {
    const { mutedInstruments, toggleInstrumentMute } = useFileContext()

    const { parsedPiece, presentInstrumentNames } = useParsedPiece()

    const { doPlayAudio, setDoPlayAudio } = usePlaybackControl()
    const { selectedPlaybackItem } = useActivePlaybackItem()

    const { ref, width } = useElementSize()

    return (
        <Paper p={'xs'} radius={'md'} shadow={'xl'}>
            <Group
                justify="stretch"
                ref={ref}
                // className={classes.playbackbar}
                gap="xs"
                wrap="nowrap"
                // p={'xs'}
                // style={transitionStyles}
            >
                <Group gap={6} wrap="nowrap">
                    <Button
                        onClick={() => setDoPlayAudio(!doPlayAudio)}
                        variant={!doPlayAudio ? 'filled' : 'filled'}
                        // c={'#455262'}
                        size={'xs'}
                        // p={0}
                        // color={''}
                        // color={doPlayAudio ? 'prim' : darken('prim', 0.1)}
                        color={doPlayAudio ? '#455262' : darken('#455262', 0.4)}
                        // fullWidth={true}
                        radius={'md'}
                        leftSection={
                            doPlayAudio ? (
                                // <AudioBarsIcon />
                                <IconPlayerStop
                                    // style={{
                                    //     width: '70%',
                                    //     height: '70%',
                                    // }}
                                    size={'1rem'}
                                    stroke={2}
                                />
                            ) : (
                                <IconPlayerPlay
                                    // style={{
                                    //     width: '70%',
                                    //     height: '70%',
                                    // }}
                                    size={'1rem'}
                                    stroke={2}
                                />
                            )
                        }
                    >
                        {selectedPlaybackItem?.name}
                        {/*{selectedPlaybackItem ? (*/}
                        {/*    `${selectedPlaybackItem.name || 'Standard'}${selectedPlaybackItem.instrument_name ? ` - ${selectedPlaybackItem.instrument_name}` : ''}`*/}
                        {/*) : (*/}
                        {/*    <Skeleton height="1rem" />*/}
                        {/*)}*/}
                    </Button>

                    {/*<Button*/}
                    {/*    variant={'filled'}*/}
                    {/*    // variant={*/}
                    {/*    //     playbackFollowsTextCursor ? 'subtle' : 'filled'*/}
                    {/*    // }*/}
                    {/*    onClick={() =>*/}
                    {/*        setPlaybackFollowsTextCursor(*/}
                    {/*            !playbackFollowsTextCursor*/}
                    {/*        )*/}
                    {/*    }*/}
                    {/*    radius={'md'}*/}
                    {/*    // label={'asdas'}*/}
                    {/*    p={0}*/}
                    {/*    pl={'0.5rem'}*/}
                    {/*    // pr={'0.5rem'}*/}
                    {/*    // w={'10rem'}*/}
                    {/*    // h={'5rem'}*/}
                    {/*    m={0}*/}
                    {/*    // size={'sm'}*/}
                    {/*    size={'xs'}*/}
                    {/*    color={*/}
                    {/*        playbackFollowsTextCursor*/}
                    {/*            ? '#455262'*/}
                    {/*            : darken('#455262', 0.4)*/}
                    {/*    }*/}

                    {/*    // color={*/}
                    {/*    //     playbackFollowsTextCursor*/}
                    {/*    //         ? 'var(--bright-color)'*/}
                    {/*    //         : 'var(--bg-color)'*/}
                    {/*    // }*/}
                    {/*>*/}
                    {/*    {playbackFollowsTextCursor ? (*/}
                    {/*        <IconCursorText*/}
                    {/*            style={{*/}
                    {/*                width: '70%',*/}
                    {/*                height: '70%',*/}
                    {/*            }}*/}
                    {/*            stroke={2}*/}
                    {/*        />*/}
                    {/*    ) : (*/}
                    {/*        <IconCursorOff*/}
                    {/*            style={{*/}
                    {/*                width: '70%',*/}
                    {/*                height: '70%',*/}
                    {/*            }}*/}
                    {/*            stroke={2}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*</Button>*/}
                </Group>

                <Container p={0} fluid />

                {/*<IconVolume size={'1rem'} color={'grey'} />*/}
                {/*<Container p={0} fluid />*/}

                {/*<Text lineClamp={1} tt="capitalize" component="div">*/}
                {/*    <TypographyStylesProvider>*/}
                <Group wrap="nowrap" gap={6}>
                    {parsedPiece &&
                        presentInstrumentNames.map((instrumentName) => (
                            <AspectRatio ratio={1} key={instrumentName}>
                                <Button
                                    key={instrumentName}
                                    variant={
                                        mutedInstruments.has(instrumentName)
                                            ? 'light'
                                            : 'filled'
                                    }
                                    color={
                                        mutedInstruments.has(instrumentName)
                                            ? '#455262'
                                            : darken('#455262', 0.4)
                                    }
                                    // color={
                                    //     mutedInstruments.has(instrumentName)
                                    //         ? 'gray'
                                    //         : // @ts-expect-error works
                                    //           instrumentColors[instrumentName]
                                    // }
                                    p={0}
                                    size={'xs'}
                                    onClick={() =>
                                        toggleInstrumentMute(instrumentName)
                                    }
                                    // fullWidth={true}
                                    radius="md"
                                    fw={460}
                                    // @ts-expect-error works
                                    tt={width > 450 ? 'capitalize' : ''}
                                >
                                    {/*{width > 450*/}
                                    {/*    ? instrumentName*/}
                                    {/*    : instrumentName*/}
                                    {/*            .toLowerCase()*/}
                                    {/*            .startsWith('dj')*/}
                                    {/*      ? instrumentName.slice(0, 2)*/}
                                    {/*      : instrumentName*/}
                                    {/*            .slice(0, 1)*/}
                                    {/*            .toUpperCase()}*/}

                                    {instrumentName
                                        .toLowerCase()
                                        .startsWith('dj')
                                        ? instrumentName.slice(0, 2)
                                        : instrumentName
                                              .slice(0, 1)
                                              .toUpperCase()}
                                </Button>
                            </AspectRatio>
                        ))}
                </Group>

                {/*<Container p={0} fluid />*/}
            </Group>
        </Paper>
    )
}
