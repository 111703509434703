import { useContext, createContext, useState, FC, ReactNode } from 'react'
import { getContext } from 'tone'
import { PlaybackItemDesc } from '../types/PlaybackItemDesc'

interface PlaybackControlContextType {
    doPlayAudio: boolean
    setDoPlayAudio: (val: boolean) => void
}

interface activePlaybackItemContextType {
    selectedPlaybackItem: PlaybackItemDesc | null
    setSelectedPlaybackItem: (item: PlaybackItemDesc | null) => void
}

const ActivePlaybackItemContext =
    createContext<activePlaybackItemContextType | null>(null)

const DoPlayAudioContext = createContext<PlaybackControlContextType | null>(
    null
)

export const usePlaybackControl = () => {
    return useContext(DoPlayAudioContext)!
}

export const useActivePlaybackItem = () => {
    return useContext(ActivePlaybackItemContext)!
}

export const PlaybackControlProvider: FC<{
    children: ReactNode
}> = ({ children }) => {
    const [doPlayAudio, internalSetDoPlayAudio] = useState<boolean>(false)
    const [selectedPlaybackItem, setSelectedPlaybackItem] =
        useState<PlaybackItemDesc | null>(null)

    function setDoPlayAudio(val: boolean) {
        if (val && getContext().state !== 'running') {
            getContext()
                .resume()
                .then(() => {
                    console.log('Audio context resumed')
                })
                .catch((err) => {
                    console.error('Error resuming audio context:', err)
                })
                .finally(() => {
                    // internalSetDoPlayAudio(val)
                })
            internalSetDoPlayAudio(val)
        } else {
            internalSetDoPlayAudio(val)
        }
    }

    return (
        <ActivePlaybackItemContext.Provider
            value={{ selectedPlaybackItem, setSelectedPlaybackItem }}
        >
            <DoPlayAudioContext.Provider
                value={{ doPlayAudio, setDoPlayAudio }}
            >
                {children}
            </DoPlayAudioContext.Provider>
        </ActivePlaybackItemContext.Provider>
    )
}
