export function get_pulse_times(
    strength: number,
    swing_offsets: number[] | null,
    swing_ratios: number[] | null
): number[] {
    if (strength < 0 || strength > 1) {
        throw new Error('strength must be between 0 and 1')
    }

    if (swing_offsets !== null && swing_ratios !== null) {
        console.warn(
            'Both swing_offsets and swing_ratios are provided. Using swing_offsets.'
        )
    }

    if (swing_offsets !== null) {
        return swing_offsets.map((offset, i) => {
            return i + (strength * offset) / 100
        })
    }

    if (swing_ratios !== null) {
        const nPulses = swing_ratios.length

        // Equidistant ratios (original equal distribution)
        const equidistant_ratios = Array(nPulses).fill(1)

        // Interpolate between equidistant and swing ratios based on strength
        let scaled_ratios = equidistant_ratios.map((eqRatio, i) => {
            return eqRatio + strength * (swing_ratios[i] - eqRatio)
        })

        // Ensure they're non-negative (minimum 0)
        scaled_ratios = scaled_ratios.map((x) => Math.max(0, x))

        // Normalize to ensure they sum to nPulses
        const sum = scaled_ratios.reduce((a, b) => a + b, 0)
        const normalized_ratios = scaled_ratios.map((x) => (x / sum) * nPulses)

        // Cumulative sum to get pulse times
        const pulse_times = []
        let cumsum = 0
        for (let i = 0; i < nPulses; i++) {
            pulse_times.push(cumsum)
            cumsum += normalized_ratios[i]
        }

        return pulse_times
    }

    throw Error('Either swing_offsets or swing_ratios must be provided.')
}

// The transform_onsets function remains unchanged
export function transform_onsets(
    onsets: number[],
    pulse_times: number[]
): number[] {
    // if pulse times are identical to there indices, just return the onsets
    if (pulse_times.every((v, i) => v === i)) {
        return onsets
    }

    // The length of pulse durations is the number of pulses per beat.
    const nPulsesPerBeat = pulse_times.length

    // Ensure the number of pulses per beat is greater than 0.
    if (nPulsesPerBeat <= 0) {
        throw new Error('nPulsesPerBeat must be greater than 0')
    }

    const pulse_durations: number[] = []
    for (let i = 0; i < nPulsesPerBeat; i++) {
        const next_pulse_time =
            i == nPulsesPerBeat - 1
                ? nPulsesPerBeat + pulse_times[0]
                : pulse_times[i + 1]

        pulse_durations.push(next_pulse_time - pulse_times[i])
    }

    // for each onset
    return onsets.map((onset) => {
        // determine the number of previous beats and relative offset within the beat
        const relative_beat_offset = onset % nPulsesPerBeat
        const n_beats_before = Math.floor(onset / nPulsesPerBeat)

        // determine the number of previous pulses from the beat and relative offset within the pulse
        const n_pulses_before = Math.floor(relative_beat_offset)
        const relative_pulse_offset = relative_beat_offset - n_pulses_before

        // now transform the relative offsets
        const transformed_pulse_offset =
            relative_pulse_offset * pulse_durations[n_pulses_before]

        const transformed_beat_offset = pulse_times[n_pulses_before]

        return (
            n_beats_before * nPulsesPerBeat +
            transformed_beat_offset +
            transformed_pulse_offset
        )
    })
}
